<template>
  <div class="button-container">
    <b-button variant="outline-success" @click="redirectToPreviewTickets">
      {{ $t('Success.previewTickets') }}
    </b-button>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import _ from 'lodash';
import config from '@/config';

export default Vue.extend({
  name: 'PreviewTicketsButton',
  data() {
    return {
      siteUrl: config.RAFFLEBOX_URL || 'https://rafflebox.ca',
    }
  },
  methods: {
    redirectToPreviewTickets() {
      const parentRaffleOrderId = this.$route.params.orders
        ? JSON.parse(this.$route.params.orders)[0]?.orderIdLong
        : null;

      window.open(`${this.siteUrl}/order/${parentRaffleOrderId}`);
    }
  },
});
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: center;
}
</style>
